/* You can add global styles to this file, and also import other style files */
/* @import './../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss'; */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: Darkseed;
  src: url("./assets/fonts/Dark-Seed.otf") format("opentype");
}
.spacer-s {
  height: 6px;
}
.spacer {
  height: 12px;
}
.spacer-m {
  height: 26px;
}
.spacer-l {
  height: 64px;
}
.spacer-xl {
  height: 92px;
}
.popover {
  padding: 0px !important;
}
.ant-popover-inner-content {
  padding: 0px;
}
